/*
 * Component: Callout
 * ------------------
 */

// Base styles (regardless of theme)
.callout {
  @if $enable-rounded {
    @include border-radius($border-radius);
  }

  @if $enable-shadows {
    @extend .elevation-1;
  } @else {
    border: 1px solid $gray-300;
  }

  @extend .mb-3;
  background-color: $white;
  border-left: 5px solid $gray-200;
  padding: .5rem 1rem .5rem .5rem;

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      color: $gray-200;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  // Themes for different contexts
  &.callout-danger {
    border-left-color: darken(theme-color('danger'), 10%);
  }

  &.callout-warning {
    border-left-color: darken(theme-color('warning'), 10%);
  }

  &.callout-info {
    border-left-color: darken(theme-color('info'), 10%);
  }

  &.callout-success {
    border-left-color: darken(theme-color('success'), 10%);
  }
}
