/*
 * Component: Labels
 * -----------------
 */

.label-default {
  background-color: $gray-500;
  color: $gray-700;
}

.label-danger {
  @extend .bg-danger;
}

.label-info {
  @extend .bg-info;
}

.label-warning {
  @extend .bg-warning;
}

.label-primary {
  @extend .bg-primary;
}

.label-success {
  @extend .bg-success;
}
