/*
 * Component: Direct Chat
 * ----------------------
 */
 
.direct-chat {
  .card-body {
    overflow-x: hidden;
    padding: 0;
    position: relative;
  }

  &.chat-pane-open {
    .direct-chat-contacts {
      @include translate(0, 0);
    }
  }
}

.direct-chat-messages {
  @include translate(0, 0);
  height: 250px;
  overflow: auto;
  padding: 10px;
}

.direct-chat-msg,
.direct-chat-text {
  display: block;
}

.direct-chat-msg {
  @include clearfix;
  margin-bottom: 10px;
}

.direct-chat-messages,
.direct-chat-contacts {
  transition: transform .5s ease-in-out;
}

.direct-chat-text {
  @if $enable-rounded {
    @include border-radius($border-radius-lg);
  }

  background: $direct-chat-default-msg-bg;
  border: 1px solid $direct-chat-default-msg-border-color;
  color: $direct-chat-default-font-color;
  margin: 5px 0 0 50px;
  padding: 5px 10px;
  position: relative;

  //Create the arrow
  &::after,
  &::before {
    border: solid transparent;
    border-right-color: $direct-chat-default-msg-border-color;
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 15px;
    width: 0;
  }

  &::after {
    border-width: 5px;
    margin-top: -5px;
  }

  &::before {
    border-width: 6px;
    margin-top: -6px;
  }

  .right & {
    margin-left: 0;
    margin-right: 50px;

    &::after,
    &::before {
      border-left-color: $direct-chat-default-msg-border-color;
      border-right-color: transparent;
      left: 100%;
      right: auto;
    }
  }
}

.direct-chat-img {
  @include border-radius(50%);
  float: left;
  height: 40px;
  width: 40px;

  .right & {
    float: right;
  }
}

.direct-chat-info {
  display: block;
  font-size: $font-size-sm;
  margin-bottom: 2px;
}

.direct-chat-name {
  font-weight: 600;
}

.direct-chat-timestamp {
  color: darken($gray-500, 25%);
}

//Direct chat contacts pane
.direct-chat-contacts-open {
  .direct-chat-contacts {
    @include translate(0, 0);
  }
}

.direct-chat-contacts {
  @include translate(101%, 0);
  background: $gray-900;
  bottom: 0;
  color: $white;
  height: 250px;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100%;
}

//Contacts list -- for displaying contacts in direct chat contacts pane
.contacts-list {
  @extend .list-unstyled;

  > li {
    @include clearfix;
    border-bottom: 1px solid rgba($black, 0.2);
    margin: 0;
    padding: 10px;

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.contacts-list-img {
  @include border-radius(50%);
  float: left;
  width: 40px;
}

.contacts-list-info {
  color: $white;
  margin-left: 45px;
}

.contacts-list-name,
.contacts-list-status {
  display: block;
}

.contacts-list-name {
  font-weight: 600;
}

.contacts-list-status {
  font-size: $font-size-sm;
}

.contacts-list-date {
  color: $gray-500;
  font-weight: normal;
}

.contacts-list-msg {
  color: darken($gray-500, 25%);
}

//Direct Chat Variants
.direct-chat-danger {
  @include direct-chat-variant(theme-color('danger'));
}

.direct-chat-primary {
  @include direct-chat-variant(theme-color('primary'));
}

.direct-chat-warning {
  @include direct-chat-variant(theme-color('warning'));
}

.direct-chat-info {
  @include direct-chat-variant(theme-color('info'));
}

.direct-chat-success {
  @include direct-chat-variant(theme-color('success'));
}
