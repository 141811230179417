/*
 * Component: Table
 * ----------------
 */

.table {
  &:not(.table-dark) {
    color: inherit;
  }

  // fixed table head
  &.table-head-fixed {
    thead tr:nth-child(1) th {
      background-color: $white;
      border-bottom: none;
      box-shadow: inset 0 1px 0 $table-border-color,
                  inset 0 -1px 0 $table-border-color;
      position: sticky;
      top: 0;
      z-index: 10;
    }

    &.table-dark {
      thead tr {
        &:nth-child(1) th {
          background-color: $table-dark-bg;
          box-shadow: inset 0 1px 0 $table-dark-border-color,
                      inset 0 -1px 0 $table-dark-border-color;
        }
      }
    }
  }

  // no border
  &.no-border {
    &,
    td,
    th {
      border: 0;
    }
  }

  // .text-center in tables
  &.text-center {
    &,
    td,
    th {
      text-align: center;
    }
  }

  .table-valign-middle {
    thead > tr > th,
    thead > tr > td,
    tbody > tr > th,
    tbody > tr > td {
      vertical-align: middle;
    }
  }

  .card-body.p-0 & {
    thead > tr > th,
    thead > tr > td,
    tbody > tr > th,
    tbody > tr > td {
      &:first-of-type {
        @extend .pl-4;
      }

      &:last-of-type {
        @extend .pr-4;
      }
    }
  }
}
