/*
 * Component: Elevation
 * --------------------
 */

.elevation-0 {
  box-shadow: none !important;
}

.elevation-1 {
  box-shadow: 0 1px 3px rgba($black, 0.12), 0 1px 2px rgba($black, 0.24) !important;
}

.elevation-2 {
  box-shadow: 0 3px 6px rgba($black, 0.16), 0 3px 6px rgba($black, 0.23) !important;
}

.elevation-3 {
  box-shadow: 0 10px 20px rgba($black, 0.19), 0 6px 6px rgba($black, 0.23) !important;
}

.elevation-4 {
  box-shadow: 0 14px 28px rgba($black, 0.25), 0 10px 10px rgba($black, 0.22) !important;
}

.elevation-5 {
  box-shadow: 0 19px 38px rgba($black, 0.30), 0 15px 12px rgba($black, 0.22) !important;
}
