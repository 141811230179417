/*
 * Component: Sidebar Mini
 * -----------------------
 */

// Mixin

@mixin sidebar-mini-breakpoint() {
  // A fix for text overflow while transitioning from sidebar mini to full sidebar
  .nav-sidebar,
  .nav-sidebar > .nav-header,
  .nav-sidebar .nav-link {
    white-space: nowrap;
    overflow: hidden;
  }

  // When the sidebar is collapsed...
  &.sidebar-collapse {
    .d-hidden-mini {
      display: none;
    }

    // Apply the new margins to the main content and footer
    .content-wrapper,
    .main-footer,
    .main-header {
      margin-left: $sidebar-mini-width !important;
    }

    // Make the sidebar headers
    .nav-sidebar .nav-header {
      display: none;
    }

    .nav-sidebar .nav-link p {
      width: 0;
    }

    .sidebar .user-panel > .info,
    .nav-sidebar .nav-link p,
    .brand-text {
      margin-left: -10px;
      opacity: 0;
      visibility: hidden;
    }

    .logo-xl {
      opacity: 0;
      visibility: hidden;
    }

    .logo-xs {
      display: inline-block;
      opacity: 1;
      visibility: visible;
    }

    // Modify the sidebar to shrink instead of disappearing
    .main-sidebar {
      overflow-x: hidden;

      &,
      &::before {
        // Don't go away! Just shrink
        margin-left: 0;
        width: $sidebar-mini-width;
      }

      .user-panel {
        .image {
          float: none;
        }
      }

      &:hover, 
      &.sidebar-focused {
        width: $sidebar-width;

        .user-panel {
          text-align: left;

          .image {
            float: left;
          }
        }

        .user-panel > .info,
        .nav-sidebar .nav-link p,
        .brand-text,
        .logo-xl {
          display: inline-block;
          margin-left: 0;
          opacity: 1;
          visibility: visible;
        }

        .logo-xs {
          opacity: 0;
          visibility: hidden;
        }

        .brand-image {
          margin-right: .5rem;
        }

        // Make the sidebar links, menus, labels, badges
        // and angle icons disappear
        .sidebar-form,
        .user-panel > .info {
          display: block !important;
          -webkit-transform: translateZ(0);
        }

        .nav-sidebar > .nav-item > .nav-link > span {
          display: inline-block !important;
        }
      }
    }

    // Make an element visible only when sidebar mini is active
    .visible-sidebar-mini {
      display: block !important;
    }

    &.layout-fixed {
      .main-sidebar:hover {
        .brand-link {
          width: $sidebar-width;
        }
      }

      .brand-link {
        width: $sidebar-mini-width;
      }
    }
  }
}


// Logo style
.logo-xs,
.logo-xl {
  margin: 0;
  opacity: 1;
  position: absolute;
  visibility: visible;

  &.brand-image-xs {
    left: 18px;
    top: 12px;
  }

  &.brand-image-xl {
    left: 12px;
    top: 6px;
  }
}

.logo-xs {
  opacity: 0;
  visibility: hidden;

  &.brand-image-xl {
    left: 16px;
    top: 8px;
  }
}

.brand-link {
  &.logo-switch {
    &::before {
      content: '\00a0';
    }
  }
}

// Add sidebar-mini class to the body tag to activate this feature
.sidebar-mini {
  @include media-breakpoint-up(lg) {
    @include sidebar-mini-breakpoint;
  }
}

.sidebar-mini-md {
  @include media-breakpoint-up(md) {
    @include sidebar-mini-breakpoint;
  }
}

.nav-sidebar {
  position: relative;

  &:hover {
    overflow: visible;
  }
}

.sidebar-form,
.nav-sidebar > .nav-header {
  overflow: hidden;
  text-overflow: clip;
}

.nav-sidebar .nav-item > .nav-link {
  position: relative;

  > .float-right {
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
  }
}

.sidebar .nav-link p,
.main-sidebar .brand-text,
.main-sidebar .logo-xs,
.main-sidebar .logo-xl,
.sidebar .user-panel .info {
  @include transition(margin-left $transition-speed linear, opacity $transition-speed ease, visibility $transition-speed ease)
}
